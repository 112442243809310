import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from "react-helmet"
import Nav from "../components/nav"
import Footer from "../components/footer"
import BackgroundImage from "../components/background-image"
import BGImage from "../images/about-page-image.jpg"
import Services from "../components/services"
import { DeepCleaningIcon, StandardCleaningIcon, MoveOutsIcon, SoapIcon, BroomIcon } from '../components/ServiceIcons'
import { parseDetails } from '../utils/parseDetails'

const iconMap = {
  'deep-cleaning': DeepCleaningIcon,
  'standard-cleaning': StandardCleaningIcon,
  'move-outs': MoveOutsIcon,
  'broom-icon':BroomIcon,
  'soap-icon':SoapIcon,
}

const ServicePageTemplate = ({ data }) => {
  const { title, description, details, icon, cta } = data.serviceItem
  const Icon = iconMap[icon] || null

  const renderIcon = (className) => {
    if (Icon) {
      return <Icon className={className} />
    }
    return null
  }

  return (
    <main className="flex flex-col min-h-screen">
      <Helmet>
        <title>Rancho Bernardo Maids - {title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Nav />
      <BackgroundImage image={BGImage} mobileImage={BGImage} alt={"forest background"} height={"h-96"} backgroundHeight={"h-128"} hero={true}>
        <div className="flex items-center justify-center h-full bg-black bg-opacity-50 rounded-lg mt-12">
          <h1 className="text-4xl md:text-6xl lg:text-8xl text-center text-white font-bold p-6">{title}</h1>
        </div>
      </BackgroundImage>
      <div className="container mx-auto px-4 py-12 flex-grow">
        <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="p-8">
            <div className="flex items-center mb-6">
              {renderIcon("w-12 h-12 text-malibu-500 mr-4")}
              <h2 className="text-3xl font-bold text-gray-800 border-b pb-2">About {title}</h2>
            </div>
            <p className="text-lg text-gray-600 mb-8 leading-relaxed">{description}</p>
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">What's Included</h3>
            <div 
              className="bg-gray-100 p-6 rounded-lg text-gray-700 leading-loose"
              dangerouslySetInnerHTML={{ __html: parseDetails(details) }}
            />
          </div>
        </div>
      </div>
      <div className="bg-malibu-500 dark:bg-malibu-700 px-4 py-16">
        <div className="max-w-5xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center mb-6 md:mb-0">
            {/* {renderIcon("w-16 h-16 text-white mr-4")} */}
            <h2 className="text-2xl md:text-3xl text-white font-bold max-w-lg">
              {cta}
            </h2>
          </div>
          <Link 
            to="/contact" 
            className="bg-white text-malibu-500 font-bold py-3 px-6 rounded-full shadow-lg hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105"
          >
            Request Free Estimate
          </Link>
        </div>
      </div>
      <section className="bg-gray-100 py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mt-8 text-gray-800">Our Other Services</h2>
          <Services />
        </div>
      </section>
      <Footer />
    </main>
  )
}

export const query = graphql`
  query($id: String!) {
    serviceItem(id: { eq: $id }) {
      title
      description
      details
      icon
      cta
    }
  }
`

export default ServicePageTemplate